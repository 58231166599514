<template>
  <div class="register-document fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-row justify="center" class="mt-0">
          <v-col cols="12" class="mt-0 py-0">
            <div class="my-0 pt-0 primary--text">
              <h1 class="text-center today-regular text-title">REGISTRAR DOCUMENTO</h1>
              <h3 class="primary--text text-center mb-0 today-light">Escanea la parte delantera y posterior de tu cédula</h3>
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="12">

            <v-img src="https://app-biocredit.s3.amazonaws.com/vardi/identificacion-personal.png" height="180" class="mt-4" contain alt="Registro de documento"></v-img>

            <v-row justify="center" class="mt-3">
              <v-col cols="10" md="8" lg="8">
                <ul class="primary--text">
                  <li class="mt-3 text-justify py-1"><h2 class="today-light line-height-1 text-list">Ubica tu cédula en una superficie plana y con buena iluminación.</h2></li>
                  <li class="mt-3 text-justify py-1"><h2 class="today-light line-height-1 text-list">Asegúrate enfocar bien la imagen en el recuadro.</h2></li>
                  <li class="mt-3 text-justify py-1"><h2 class="today-light line-height-1 text-list">Espera unos segundos mientras la imagen carga.</h2></li>
                </ul>
              </v-col>
            </v-row>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    @click="goUploadDocument"
                    color="primary"
                    class="px-12 text-capitalize vardi-button"
                    elevation="0">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";

export default {
  name: 'RegisterDocument',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      settings: []
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem('settings'));
    console.log('RegisterDocument', this.settings);
  },
  methods: {
    goUploadDocument() {
      this.$router.push('/subir-documento');
    }
  }
}
</script>
<style scoped>

.v-toolbar__content {
  justify-content: left !important;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-btn {
  letter-spacing: 0 !important;
  border-radius: 6px;
}

li::marker {
  color: #FFD100;
}

</style>